body {
  background: #fdf6d8;
  overflow-x: hidden;
  height: 100%;
  background-image: url("./Images/bgpattern.png");
}

.item-background {
  background: #fdf6d8;
  background-image: url("./Images/bgpattern.png");
}

/* Logo Section */

.logoImg {
  width: 68px;
  height: 68px;
  margin: 25px;
}

.logoSideNav {
  width: 70px;
  height: 70px;
  margin: 5px 28px;
}

/* Form Style */

.button {
  background: none;
  border: 1px solid #8f774a;
  color: #8f774a;
  border-radius: 6px;
  padding: 6px 28px;
  width: fit-content;
  transition: width 1s;
}

.button a:hover {
  text-decoration: none;
}
.button:hover {
  background-color: #0e2d1c;
  color: #fdf6d8;
}

input {
  background: none !important;
  border: 1px solid black !important;
}

label {
  font-weight: bold;
}

select {
  background-color: #fdf6d8 !important;
  border: 1px solid #000 !important;
}

.btn-float-right {
  float: right;
}

.contained {
  position: relative;
  text-align: center;
  color: white;
}
.centered {
  position: absolute;
  top: 50%;
  left: 35%;
  transform: translate(-50%, -50%);
}

.centered h2 {
  color: #fdf6d8;
  font-size: 3.625rem;
  font-weight: 600;
  font-family: "serif";
}

.dHide{
  display: none;
}

/* Mobile View */
@media (max-width: 991px) {
  .dHide{
    display: initial;
  }
  .button {
    background: none;
    border: 1px solid #8f774a;
    color: #8f774a;
    border-radius: 6px;
    padding: 2px 22px;
    width: fit-content;
    transition: width 1s;
  }
  .btn-float-right {
    float: unset;
  }
  .btn-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
img.d-block.w-100 {
  height: 600px;
}

div.options {
  float: right;
  margin-top: 5%;
}
i.fas.fa-clipboard-list {
  margin-left: 10px;
}

/* Mobile view */

@media (max-width: 991px) {
  .centered h2 {
    font-size: 18px;
  }
  img.d-block.w-100 {
    height: 400px;
  }
  .ml-3,
  .mx-3 {
    margin: 0px !important;
  }
  th {
    width: 20px !important;
  }
  div.col-md-6.mt {
    margin-top: 20px;
  }
  div.options {
    float: right;
    margin-top: 15%;
  }
}

.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.scrollbar {
  overflow-y: auto;
  white-space: nowrap;
}

.content-heading {
  font-family: Garamond;
  color: #072d19;
  font-size: 3rem;
  font-weight: 600;
}

.content-subheading {
  font-family: Garamond;
  color: #072d19;
  font-size: 1.8rem;
  font-weight: 600;
}

.table-head-background th {
  background-color: #072d19 !important;
  font-size: 1rem !important;
  font-weight: 800 !important;
  color: #fdf6dc !important;
}
